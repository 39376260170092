/**
 * Phone fields formatting library.
 */
(function ($) {
  var numberFormats = {
    en_US: {
      format: '(000) 000-0000',
    },
    en_AU: {
      format: '00 0000 0000',
    },
    // numbers only, max of 10 digits
    en_CA: {
      format: '0'.repeat(10),
    },
    // numbers only, max of 10 digits
    fr_CA: {
      format: '0'.repeat(10),
    },
    // numbers only, max of 11 digits
    ja_JP: {
      format: '0'.repeat(11),
    },
    // numbers only, max of 10 digits
    nl_NL: {
      format: '0'.repeat(10),
    },
    // numbers only, max of 15 digits
    fallback: {
      format: '0'.repeat(15),
    },
  };

  /**
   * Get format for the current country.
   * @return {*}
   */
  var getFormat = function () {
    var country = !window.ACC ? '' : window.ACC.languageISO;
    return country in numberFormats ? numberFormats[country] : numberFormats.fallback;
  };

  /**
   * Init phone fields by given selector(s).
   * @param selector
   */
  function init(selector) {
    var $elements = $(selector);
    if (!$elements || !$elements.length) {
      return;
    }

    var format = getFormat().format;
    if (format) {
      $elements.mask(format);
    }
  }

  $(function () {
    window.addEventListener('trek:format.phone.field', () => {
      init('.phone-field');
    });

    init('.phone-field');
  });
})(jQuery);
