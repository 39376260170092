ACC.address = {
  spinner: $("<img src='" + ACC.config.commonResourcePath + "/images/spinner.gif' />"),
  addressID: '',

  handleChangeAddressButtonClick: function () {
    ACC.address.addressID = $(this).data('address') ? $(this).data('address') : '';
    $('#summaryDeliveryAddressFormContainer').show();
    $('#summaryOverlayViewAddressBook').show();
    $('#summaryDeliveryAddressBook').hide();

    $.getJSON(getDeliveryAddressesUrl, ACC.address.handleAddressDataLoad);
    return false;
  },

  handleAddressDataLoad: function (data) {
    ACC.address.setupDeliveryAddressPopupForm(data);

    // Show the delivery address popup
    $.colorbox({
      inline: true,
      href: '#summaryDeliveryAddressOverlay',
      overlayClose: false,
      onOpen: function () {
        // empty address form fields
        ACC.address.emptyAddressForm();
        $(document).on('change', '#saveAddress', function () {
          var saveAddressChecked = $(this).prop('checked');
          $('#defaultAddress').prop('disabled', !saveAddressChecked);
          if (!saveAddressChecked) {
            $('#defaultAddress').prop('checked', false);
          }
        });
      },
      onComplete: function () {
        ACC.common.refreshScreenReaderBuffer();
        $.colorbox.resize();
      },
      onClosed: function () {
        ACC.common.refreshScreenReaderBuffer();
      },
    });
  },

  setupDeliveryAddressPopupForm: function (data) {
    // Fill the available delivery addresses
    $('#summaryDeliveryAddressBook').html($('#deliveryAddressesTemplate').tmpl({addresses: data}));
    // Handle selection of address
    $('#summaryDeliveryAddressBook button.use_address').click(ACC.address.handleSelectExistingAddressClick);
    // Handle edit address
    $('#summaryDeliveryAddressBook button.edit').click(ACC.address.handleEditAddressClick);
    // Handle set default address
    $('#summaryDeliveryAddressBook button.default').click(ACC.address.handleDefaultAddressClick);
  },

  emptyAddressForm: function () {
    var options = {
      url: getDeliveryAddressFormUrl,
      data: {addressId: ACC.address.addressID, createUpdateStatus: ''},
      type: 'GET',
      success: function (data) {
        $('#summaryDeliveryAddressFormContainer').html(data);
        ACC.address.bindCreateUpdateAddressForm();
      },
    };

    $.ajax(options);
  },

  handleSelectExistingAddressClick: function () {
    var addressId = $(this).attr('data-address');
    $.postJSON(setDeliveryAddressUrl, {addressId: addressId}, ACC.address.handleSelectExitingAddressSuccess);
    return false;
  },

  handleEditAddressClick: function () {
    $('#summaryDeliveryAddressFormContainer').show();
    $('#summaryOverlayViewAddressBook').show();
    $('#summaryDeliveryAddressBook').hide();

    var addressId = $(this).attr('data-address');
    var options = {
      url: getDeliveryAddressFormUrl,
      data: {addressId: addressId, createUpdateStatus: ''},
      target: '#summaryDeliveryAddressFormContainer',
      type: 'GET',
      success: function (data) {
        ACC.address.bindCreateUpdateAddressForm();
        $.colorbox.resize();
      },
      error: function (xht, textStatus, ex) {
        window.vm.toast({type: 'error', message: 'Failed to update cart.', showClose: true});
      },
    };

    $(this).ajaxSubmit(options);
    return false;
  },

  handleDefaultAddressClick: function () {
    var addressId = $(this).attr('data-address');
    var options = {
      url: setDefaultAddressUrl,
      data: {addressId: addressId},
      type: 'GET',
      success: function (data) {
        ACC.address.setupDeliveryAddressPopupForm(data);
      },
      error: function (xht, textStatus, ex) {
        window.vm.toast({type: 'error', message: 'Failed to update address book.', showClose: true});
      },
    };

    $(this).ajaxSubmit(options);
    return false;
  },

  handleSelectExitingAddressSuccess: function (data) {
    if (data != null) {
      ACC.refresh.refreshPage(data);
      parent.$.colorbox.close();
    } else {
      window.vm.toast({type: 'error', message: 'Failed to set delivery address.', showClose: true});
    }
  },

  bindCreateUpdateAddressForm: function () {
    $('.create_update_address_form').each(function () {
      var options = {
        type: 'POST',
        beforeSubmit: function () {
          $('#checkout_delivery_address').block({message: ACC.address.spinner});
        },
        success: function (data) {
          $('#summaryDeliveryAddressFormContainer').html(data);
          var status = $('.create_update_address_id').attr('status');
          if (status != null && 'success' === status.toLowerCase()) {
            ACC.refresh.getCheckoutCartDataAndRefreshPage();
            parent.$.colorbox.close();
          } else {
            ACC.address.bindCreateUpdateAddressForm();
            $.colorbox.resize();
          }
        },
        error: function (xht, textStatus, ex) {
          window.vm.toast({type: 'error', message: 'Failed to update cart.', showClose: true});
        },
        complete: function () {
          $('#checkout_delivery_address').unblock();
        },
      };

      $(this).ajaxForm(options);
    });
  },

  refreshDeliveryAddressSection: function (data) {
    $('.summaryDeliveryAddress').replaceWith($('#deliveryAddressSummaryTemplate').tmpl(data));
  },

  bindSuggestedDeliveryAddresses: function () {
    var status = $('.add_edit_delivery_address_id').attr('status');
    if (status != null && 'hasSuggestedAddresses' == status) {
      ACC.address.showSuggestedAddressesPopup();
    }
  },

  showSuggestedAddressesPopup: function () {
    $.colorbox({
      inline: true,
      height: false,
      width: 525,
      href: '#popup_suggested_delivery_addresses',
      overlayClose: false,
      onComplete: function () {
        $(this).colorbox.resize();
      },
    });
  },

  bindCountrySpecificAddressForms: function () {
    $('#countrySelector :input').on('change', function () {
      var options = {
        addressCode: '',
        countryIsoCode: $(this).val(),
      };
      ACC.address.displayCountrySpecificAddressForm(options, ACC.address.showAddressFormButtonPanel);
    });
  },

  showAddressFormButtonPanel: function () {
    if ($('#countrySelector :input').val() !== '') {
      $('#addressform_button_panel').show();
    }
  },

  bindToColorboxClose: function () {
    $(document).on('click', '.closeColorBox', function () {
      $.colorbox.close();
    });
  },

  displayCountrySpecificAddressForm: function (options, callback) {
    $.ajax({
      url: ACC.config.encodedContextPath + '/my-account/addressform',
      async: true,
      data: options,
      dataType: 'html',
      beforeSend: function () {
        $('#i18nAddressForm').html(ACC.address.spinner);
      },
    }).done(function (data) {
      $('#i18nAddressForm').html($(data).html());
      if (typeof callback == 'function') {
        callback.call();
      }
    });
  },

  bindToChangeAddressButton: function () {
    $(document).on('click', '.summaryDeliveryAddress .editButton', ACC.address.handleChangeAddressButtonClick);
  },
  showRemoveAddressConfirmation: function () {
    $(document).on('click', '.removeAddress', function () {
      var addressId = $(this).data('addressId');
      $.colorbox({
        inline: true,
        height: 247,
        width: 850,
        href: '#popup_confirm_address_removal_' + addressId,
        onComplete: function () {
          $(this).colorbox.resize();
        },
      });
    });
  },
  bindViewAddressBook: function () {
    $(document).on('click', '#summaryOverlayViewAddressBook', function () {
      $('#summaryDeliveryAddressFormContainer').hide();
      $('#summaryOverlayViewAddressBook').hide();
      $('#summaryDeliveryAddressBook').show();
      $.colorbox.resize();
    });
  },
  populateOptInVal: function () {
    var optin = $('#optInVal').val();
    if (optin == 'true') {
      $('#opt-in').attr('checked', 'checked');
    } else {
      if ($('#opt-in').is(':checked')) {
        $('#opt-in').removeAttr('checked');
      }
    }
  },
};

// Address Verification
$(document).ready(function () {
  var defaultAddress = $('#addressDef').val();
  if (defaultAddress == 'true') {
    $('#defaultAddress').attr('checked', 'checked');
  } else {
    if ($('#defaultAddress').is(':checked')) {
      $('#defaultAddress').removeAttr('checked');
    }
  }
  ACC.address.populateOptInVal();
  ACC.address.bindToChangeAddressButton();
  ACC.address.bindCreateUpdateAddressForm();
  ACC.address.bindSuggestedDeliveryAddresses();
  ACC.address.bindCountrySpecificAddressForms();
  ACC.address.showAddressFormButtonPanel();
  ACC.address.bindViewAddressBook();
  ACC.address.showRemoveAddressConfirmation();
  ACC.address.bindToColorboxClose();
});
